import React, { useEffect, useState } from 'react';
import Confirmation from './Confirmation';
import Selection from './Selection';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const Order = () => {
    const [ hideSelection, setHideSelection ] = useState(false);
    const [ routeSelection, setRouteSelection] = useState({
        from: 'Badda',
        to: 'Dhaka'
    });

    const MyMapComponent = withScriptjs(withGoogleMap((props) =>
    <GoogleMap
      defaultZoom={8}
      defaultCenter={{ lat: 23.7806207, lng: 90.3492859 }}
    >
      {props.isMarkerShown && <Marker position={{ lat: 23.7806207, lng: 90.3492859 }} />}
    </GoogleMap>
  ))

    const [plans, setPlans] = useState([]);
    useEffect(() => {
        fetch('https://gist.githubusercontent.com/mhhakim/8f3e0280f6bb35c94007dd0bee7b3401/raw/0e6266df2181cfcc7e7d1c876da17632b507cfb5/plans.json')
            .then(res => res.json())
            .then(data => setPlans(data))
    }, [])

    return (
        <main className="container mx-auto border-t-2 border-gray-200 mt-5">
            <section className="grid md:grid-cols-4 grid-rows-2 mt-3">
                <div className="col-span-1">
                    {
                        hideSelection ? <Confirmation plans={plans} routeSelection={routeSelection}></Confirmation> : <Selection setHideSelection={setHideSelection} routeSelection={routeSelection} setRouteSelection={setRouteSelection}></Selection>
                    }
                </div>
                <div className="md:col-span-3 col-span-1 mx-5">
                <MyMapComponent
                    isMarkerShown
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBhCkJIKw3zREIsDW2Qu2ykVThLNgeSkOA&v=3.exp&libraries=geometry,drawing,places"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `400px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                />
                </div>
            </section>
        </main>
    );
};

export default Order;