import React from 'react';
import OrderPlan from '../OrderPlan/OrderPlan';

const Confirmation = (props) => {
    const { plans, routeSelection } = props;
    return (
        <div className="w-full max-w-3xl m-auto shadow border-gray-900 bg-gray-100 rounded p-5">
            <div className="flex flex-col">
                <div className="m-3 p-5 rounded grid grid-cols-12 bg-yellow-500">
                    <div className="col-span-1">
                        <span className="bg-white w-3 h-3 block rounded-full -ml-1.5"></span>
                        <span className="border bg-white w-px block h-12 justify-items-center"></span>
                        <span className="bg-white w-3 h-3 block rounded-full -ml-1.5"></span>
                    </div>
                    <div className="col-span-11">
                        <span className="block text-white font-bold text-2xl pb-2">
                            {routeSelection.from}
                    </span>
                        <span className="block text-white font-bold text-2xl pt-1">
                        {routeSelection.to}
                    </span>
                    </div>
                </div>
                {
                    plans.map(plan => <OrderPlan plan={plan}></OrderPlan>)
                }
            </div>
        </div>
    );
};

export default Confirmation;