import { faGithub, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import firebase from "firebase/app";
import "firebase/auth";
import { UserContext } from '../../App';
import { displayErrorMsg, initFirebase } from './AuthHelper';

const Login = () => {
    const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
    const githubAuthProvider = new firebase.auth.GithubAuthProvider();
    initFirebase();

    const [ loggedUser, setLoggedUser ] = useContext(UserContext);
    const [ loginInfo, setLoginInfo ] = useState({
        email: '',
        password: ''
    })
    const [ errorMsg, setErrorMsg ] = useState('');
    
    const history = useHistory();
    const location = useLocation();
    let { from } = location.state || { from: { pathname: "/" } };

    const authSuccess = (res) => {
        const { email } = res.user;
        const photo = res.user.photoURL || 'https://ph.hakim.one/react-auth/profile.png';
        const user = {
            isSignedIn: true,
            email: email,
            profilePhoto: photo
        }
        setLoggedUser(user)
        history.replace(from)
    }

    const emailLogin = (email, password) => {
        console.log(email, password)
        firebase.auth().signInWithEmailAndPassword(email, password)
            .then(res => authSuccess(res))
            .catch((error) => {
                setErrorMsg(error.message)
            });
    }

    const googleLogin = () => {
        firebase.auth()
            .signInWithPopup(googleAuthProvider)
            .then(res => authSuccess(res))
            .catch((error) => {
                setErrorMsg(error.message)
            });
    }

    const githubLogin = () => {
        firebase
            .auth()
            .signInWithPopup(githubAuthProvider)
            .then(res => authSuccess(res))
            .catch((error) => {
                setErrorMsg(error.message)
            });
    }
    const handleBlur = (e) => {
        let loginValid = false;
        if (e.target.name === 'email') {
            loginValid = /\S+@\S+\.\S+/.test(e.target.value);
            setErrorMsg('Email address is invalid.');
        }
        if (e.target.name === 'password') {
            loginValid = /^(?=.*\d)(?=.*[a-zA-Z]).{6,15}$/.test(e.target.value);
            setErrorMsg('Password is invalid.');
        }
        if (loginValid) {
            const info = { ...loginInfo };
            info[e.target.name] = e.target.value;
            setLoginInfo(info);
            setErrorMsg('');
        }
    }

    const handleSubmit = (e) => {
        const { email, password } = loginInfo;
        emailLogin( email, password );
        e.preventDefault();
    }

    return (
        <>
            <section className="w-full max-w-3xl m-auto shadow border-gray-900 rounded p-5 mt-20">
                <h1 className="py-5 text-2xl">Login</h1>
                <form onSubmit={handleSubmit}>
                    <input className="w-full p-2 mb-6 text-gray-900 border-b-2 border-gray-100 outline-none focus:bg-gray-50" placeholder="Email" type="email" onBlur={handleBlur} name="email" required />
                    <input className="w-full p-2 mb-6 text-gray-900 border-b-2 border-gray-100 outline-none focus:bg-gray-50" placeholder="Password" type="password" onBlur={handleBlur} name="password" required />
                    <div className="flex flex-row justify-between mb-5">
                        <span><input type="checkbox" /> Remember me</span>
                        <Link to="/login" className="text-yellow-700 hover:text-yellow-600">
                            Forgot password
                        </Link>
                    </div>
                    {
                        errorMsg && displayErrorMsg(errorMsg)
                    }
                    <div className="text-center m-3">
                        <input type="submit" className="py-2 cursor-pointer bg-yellow-600 hover:bg-yellow-700 text-white px-10 rounded" value="Login" />
                    </div>
                </form>
                <div className="text-center">
                    Don't have an account? <Link className="text-yellow-700 hover:text-yellow-600" to="/registration">Signup with email</Link>
                </div>
            </section>
            <section className="w-full max-w-3xl m-auto p-5">
                <div className="text-center my-3">
                    Or
            </div>
                <div className="flex flex-col gap-3 justify-center">
                    <button onClick={googleLogin} className="py-2 px-5 bg-gray-50 hover:bg-gray-100 border-gray-150 border-2 text-gray-900 rounded-3xl">
                        <FontAwesomeIcon icon={faGoogle} /> Continue with Google
                </button>
                    <button onClick={githubLogin} className="py-2 px-5 bg-gray-50 hover:bg-gray-100 border-gray-150 border-2 text-gray-900 rounded-3xl">
                        <FontAwesomeIcon icon={faGithub} /> Continue with GitHub
                </button>
                </div>
            </section>
        </>
    );
};

export default Login;