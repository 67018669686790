import React from 'react';

const NotFound = () => {
    return (
        <div className="m-20 text-center font-bold text-2xl text-red-600 p-5">
            Page not found!
        </div>
    );
};

export default NotFound;