import React from 'react';

const Selection = (props) => {
    const { setHideSelection, routeSelection, setRouteSelection } = props;

    const handleSearch = () => {
        setHideSelection(true);
    }
    const handleBlur = (e) => {
        const route = { ...routeSelection };
        route[e.target.name] = e.target.value;
        setRouteSelection(route)
    }
    return (
        <div className="w-full max-w-3xl m-auto shadow border-gray-900 bg-gray-100 rounded p-5">
            <span className="text-gray-600">Pick from</span>
            <input className="mt-3 w-full p-2 mb-6 text-gray-900 border-b-2 border-gray-100 rounded outline-none focus:bg-gray-50" placeholder="Badda" type="text" name="from" onBlur={handleBlur} />
            <span className="text-gray-600">Pick to</span>
            <input className="mt-3 w-full p-2 mb-6 text-gray-900 border-b-2 border-gray-100 rounded outline-none focus:bg-gray-50" placeholder="Mirpur" type="text" name="to" onBlur={handleBlur} />
            <span className="text-gray-600">Start from</span>
            <input className="mt-3 w-full p-2 mb-6 text-gray-900 border-b-2 border-gray-100 rounded outline-none focus:bg-gray-50" type="date" />
            <div className="text-center m-1">
                <button onClick={handleSearch} className="py-2 bg-yellow-600 text-white px-16 md:px-14 lg:px-22 xl:px-28 2xl:px-32 rounded">Search</button>
            </div>
        </div>
    );
};

export default Selection;