import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../App';
import logo from '../../images/logo-light.png';

const Header = () => {
    const [ loggedUser, setLoggedUser ] = useContext(UserContext);
    const { isSignedIn, profilePhoto } = loggedUser;

    const handleLogout = () => {
        const user = {
            isSignedIn: false,
            email: '',
            profilePhoto: ''
        }
        setLoggedUser(user);
    }

    const LoggedIn = () => {
        return (
        <>
            <img class="h-8 w-8 mt-2 lg:mb-0 lg:ml-0 ml-12 mb-3 rounded-full" src={profilePhoto} alt="" />
            <button onClick={() => handleLogout()} className="bg-red-800 text-gray-100 hover:bg-red-900 hover:text-white p-2 w-16 lg:w-auto rounded-md font-medium"> Logout </button>
        </>
        );
    }
    const NotLoggedIn = () => {
        return <Link className="bg-yellow-800 text-gray-100 hover:bg-yellow-900 hover:text-white p-2 w-14 lg:w-auto lg:mt-0 mt-3 rounded-md font-medium" to="/login"> Login </Link>;
    }

    return (
        <header classNameNameName="container">
            <nav className="bg-gray-900">
                <div className="flex lg:flex-row flex-col justify-between max-w-7xl mx-auto">
                    <div className="py-3">
                        <img src={logo} className="lg:w-1/3 w-1/6" alt="Logo" />
                    </div>
                    <div className="py-3">
                        <div className="flex lg:flex-row flex-col justify-items-end space-x-4">
                            <Link className="text-gray-100 hover:bg-gray-700 hover:text-white p-2 ml-4 lg:ml-0 rounded-md font-medium" to="/">
                                Home
                            </Link>
                            <Link className="text-gray-100 hover:bg-gray-700 hover:text-white p-2 rounded-md font-medium" to="/order">
                                Subscribe
                            </Link>
                            <Link className="text-gray-100 hover:bg-gray-700 hover:text-white p-2 rounded-md font-medium" to="/blog">
                                Blog
                            </Link>
                            <Link className="text-gray-100 hover:bg-gray-700 hover:text-white p-2 rounded-md font-medium" to="/contact">
                                Contact
                            </Link>
                            {
                                isSignedIn ? LoggedIn() : NotLoggedIn()
                            }
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Header;