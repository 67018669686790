import React from 'react';

const OrderPlan = (props) => {
    const { name, price } = props.plan;
    return (
        <div className="m-3 p-5 bg-white rounded flex flex-row">
            <img src="https://ph.hakim.one/react-auth/ticket.png" alt="" />
            <span className="font-bold ml-4 mt-2">
                {name} {price}$
            </span>
        </div>
    );
};

export default OrderPlan;