import React, { useContext, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import firebase from "firebase/app";
import "firebase/auth";
import { UserContext } from '../../App';
import { displayErrorMsg, initFirebase } from './AuthHelper';

const Registration = () => {
    initFirebase();

    const [loggedUser, setLoggedUser] = useContext(UserContext);
    const [ errorMsg, setErrorMsg ] = useState('');
    const [userReg, setUserReg] = useState({
        email: '',
        password1: '',
        password2: ''
    })

    const history = useHistory();
    const location = useLocation();
    let { from } = location.state || { from: { pathname: "/" } };

    const handleBlur = (e) => {
        let formValid = false;
        if (e.target.name === 'email') {
            formValid = /\S+@\S+\.\S+/.test(e.target.value);
            setErrorMsg('Email address is invalid.');
        }
        if (e.target.name === 'password1') {
            formValid = /^(?=.*\d)(?=.*[a-zA-Z]).{6,15}$/.test(e.target.value);
            setErrorMsg('Password should be 6-15 character long and include both number & alphabet.');
        }
        if (e.target.name === 'password2') {
            formValid = userReg.password1 === e.target.value;
            setErrorMsg('New password & confirm password doesn\'t matched.');
        }
        if (formValid) {
            const userInfo = { ...userReg };
            userInfo[e.target.name] = e.target.value;
            setUserReg(userInfo);
            setErrorMsg('');
        }
    }

    const userAuthReg = (email, password) => {
        firebase.auth().createUserWithEmailAndPassword(email, password)
            .then((result) => {
                const { email } = result.user;
                const user = {
                    isSignedIn: true,
                    email: email,
                    profilePhoto: 'https://ph.hakim.one/react-auth/profile.png'
                }
                setLoggedUser(user)
                history.replace(from)
            })
            .catch((error) => {
                setErrorMsg(error.message)
            });
    }
    const handleSubmit = (e) => {
        const { email, password1, password2 } = userReg;
        password1 === password2 ? userAuthReg( email, password1 ) : setErrorMsg('New password & confirm password mismatched!')
        e.preventDefault();
    }

    return (
        <>
            <section className="w-full max-w-3xl m-auto shadow border-gray-900 rounded p-5 mt-20">
                <h1 className="py-5 text-2xl">Create an account</h1>
                <form onSubmit={handleSubmit}>
                    <span className="text-gray-600">E-mail</span>
                    <input className="w-full py-2 mb-6 text-gray-900 border-b-2 border-gray-100 outline-none focus:bg-gray-50" placeholder="Enter your email" onBlur={handleBlur} name="email" type="email" required />
                    <span className="text-gray-600">Password</span>
                    <input className="w-full py-2 mb-6 text-gray-900 border-b-2 border-gray-100 outline-none focus:bg-gray-50" placeholder="New password" onBlur={handleBlur} name="password1" type="password" />
                    <span className="text-gray-600">Verify password</span>
                    <input className="w-full py-2 mb-6 text-gray-900 border-b-2 border-gray-100 outline-none focus:bg-gray-50" placeholder="Confirm your password" onBlur={handleBlur} name="password2" type="password" />
                    {
                        errorMsg && displayErrorMsg(errorMsg)
                    }
                    <div className="text-center m-3">
                        <input type="submit" className="cursor-pointer py-2 bg-yellow-600 hover:bg-yellow-700 text-white px-10 rounded" value="Sign Up" />
                    </div>

                </form>

                <div className="text-center">
                    Already have an account? <Link className="text-yellow-700 hover:text-yellow-600" to="/login">sign in</Link>
                </div>
            </section>
        </>
    );
};

export default Registration;