import firebase from "firebase/app";
import firebaseConfig from './firebase.config';
import "firebase/auth";

export const initFirebase = () => {
    if (firebase.apps.length === 0) {
        firebase.initializeApp(firebaseConfig);
    }
}

export const displayErrorMsg = (msg) => {
    return (
        <div className="text-red-500 text-center">{msg}</div>
    );
}