import React from 'react';
import { Link } from 'react-router-dom';

const Plan = (props) => {
    const {name, price, description, bgImage} = props.plan;
    return (
        <div className="relative">
            <img className="" src={bgImage} alt="Card" />
            <span className="absolute top-2 text-white inset-x-0 text-center font-bold text-3xl">{name}</span>
            <span className="absolute top-1/4 text-white inset-x-0 text-center font-bold text-md">{description}</span>
            <div className="absolute inset-y-1/2 inset-x-0 text-center">
                <Link className="text-white font-bold text-xl bg-indigo-500 hover:bg-indigo-600 hover:text-white px-5 py-3 rounded-3xl" to="/order">
                    BUY NOW
                </Link>
            </div>
            <span className="absolute bottom-7 text-white inset-x-0 text-center font-bold text-3xl">{price}$</span>
        </div>
    );
};

export default Plan;