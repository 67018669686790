import React from 'react';

const Blog = () => {
    return (
        <div className="m-20 text-center font-bold text-2xl text-green-600 p-5">
            Under construction
        </div>
    );
};

export default Blog;