import React, { useEffect, useState } from 'react';
import Plan from '../Plan/Plan';

const Home = () => {
    const [plans, setPlans] = useState([]);
    useEffect(() => {
        fetch('https://gist.githubusercontent.com/mhhakim/8f3e0280f6bb35c94007dd0bee7b3401/raw/0e6266df2181cfcc7e7d1c876da17632b507cfb5/plans.json')
        .then(res => res.json())
        .then(data => setPlans(data))
    }, [])
    return (
        <main className="lg:h-screen" style={{
            backgroundImage: `url("https://ph.hakim.one/react-auth/background.webp")`,
            backgroundRepeat: 'no-repeat'
        }}>
            <div className="pt-32 flex gap-5 items-center justify-center lg:flex-row flex-col">
                {
                    plans.map(plan => <Plan plan={plan}></Plan>)
                }
            </div>
        </main>
    );
};

export default Home;