import './App.css';
import Home from './components/Home/Home';
import Header from './components/Header/Header';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Login from './components/Auth/Login';
import Registration from './components/Auth/Registration';
import Order from './components/Order/Order';
import { createContext, useState } from 'react';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import Blog from './components/Blog/Blog';
import Contact from './components/Contact/Contact';
import NotFound from './components/Errors/NotFound';
export const UserContext = createContext();

function App() {
  const [ loggedUser, setLoggedUser ] = useState({
    isSignedIn: false,
    email: '',
    profilePhoto: ''
  });

  return (
    <UserContext.Provider value={[loggedUser, setLoggedUser]}>
    <Router>
      <Header />
      
      <Switch>
        <Route exact path="/">
          <Home></Home>
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/registration">
          <Registration />
        </Route>
        <PrivateRoute path="/order">
          <Order />
        </PrivateRoute>
        <Route path="/blog">
          <Blog />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>

    </Router>
    </UserContext.Provider>
  );
}

export default App;
